import axios from "axios";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TimelineIcon from "@mui/icons-material/Timeline";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { useParams } from "react-router-dom";

interface LearnHowModalProps {
  open: boolean;
  onClose: () => void;
}

const LearnHowModal: FunctionComponent<LearnHowModalProps> = ({
  open,
  onClose,
}) => {
  const { _report = "" } = useParams();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConnect = () => {
    axios.post(`/api/v1/report/${_report}/connect`);

    setShowConfirmation(true);
    setTimeout(() => {
      onClose();
      setShowConfirmation(false);
    }, 2000);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  return (
    <>
      <Overlay show={open} onClick={onClose} />
      <Root show={open}>
        <CloseDiv onClick={onClose}>
          <CloseIcon />
        </CloseDiv>
        <div style={{ padding: "24px" }}>
          <Title>Connect with a Specialist</Title>

          <BenefitsContainer>
            <BenefitItem>
              <IconWrapper>
                <SupportAgentIcon
                  style={{ fontSize: "32px", color: "#5167F6" }}
                />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>Expert Guidance</BenefitTitle>
                <BenefitDescription>
                  Work with a payments specialist who will analyze your
                  processing costs and identify savings opportunities
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>

            <BenefitItem>
              <IconWrapper>
                <TimelineIcon style={{ fontSize: "32px", color: "#10B981" }} />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>Tailored Action Plan</BenefitTitle>
                <BenefitDescription>
                  Turn your detailed AI report into a personalized strategy—our
                  specialist will help you prioritize the most impactful
                  opportunities for immediate savings.
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>

            <BenefitItem>
              <IconWrapper>
                <HandshakeIcon style={{ fontSize: "32px", color: "#FF6B6B" }} />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>No Obligation</BenefitTitle>
                <BenefitDescription>
                  Receive expert advice with no pressure or commitment to make
                  any changes
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>
          </BenefitsContainer>

          <ConsentText>
            A payments specialist will reach out to discuss your processing
            costs and explain how we can help reduce your fees.
          </ConsentText>

          <ButtonContainer>
            {!showConfirmation ? (
              <AgreeButton onClick={handleConnect}>
                Put me in touch
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M4 12h16" />
                  <path d="m12 4 8 8-8 8" />
                </svg>
              </AgreeButton>
            ) : (
              <ConfirmationMessage>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#10B981"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 6L9 17l-5-5" />
                </svg>
                We'll be in touch soon!
              </ConfirmationMessage>
            )}
          </ButtonContainer>
        </div>
      </Root>
    </>
  );
};

const Root = styled.div<{ show: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 24px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 600px;
  z-index: 1000;
  display: ${(props) => (props.show ? "block" : "none")};
  border: 1px solid rgba(81, 103, 246, 0.1);
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 800;
  color: #2a2a2a;
  margin: 0 0 24px 0;
  text-align: center;
  letter-spacing: -0.5px;
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;
`;

const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: rgba(81, 103, 246, 0.03);
  border-radius: 16px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateX(4px);
    background: rgba(81, 103, 246, 0.05);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(81, 103, 246, 0.08);
`;

const BenefitText = styled.div`
  flex: 1;
`;

const BenefitTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 0 0 4px 0;
`;

const BenefitDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  line-height: 1.5;
`;

const ConsentText = styled.p`
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  background: rgba(16, 185, 129, 0.05);
  padding: 16px;
  border-radius: 12px;
  border: 1px solid rgba(16, 185, 129, 0.1);
  margin: 24px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

const AgreeButton = styled.button`
  background: linear-gradient(90deg, #5167f6 0%, #7186ff 100%);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 14px 28px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(81, 103, 246, 0.2);
  }
`;

const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  z-index: 999;
  display: ${(props) => (props.show ? "block" : "none")};
  transition: all 0.3s ease;
`;

const ConfirmationMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #10b981;
  font-weight: 600;
  font-size: 16px;
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(4px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export default LearnHowModal;
