import { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CreditCardIcon from "@mui/icons-material/CreditCard";

interface ProcessingFeesModalProps {
  open: boolean;
  onClose: () => void;
}

const ProcessingFeesModal: FunctionComponent<ProcessingFeesModalProps> = ({
  open,
  onClose,
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  return (
    <>
      <Overlay show={open} onClick={onClose} />
      <Root show={open}>
        <CloseDiv onClick={onClose}>
          <CloseIcon />
        </CloseDiv>
        <div style={{ padding: "24px" }}>
          <Title>Understanding Transaction Fees</Title>

          <BenefitsContainer>
            <BenefitItem feeType="processing">
              <IconWrapper>
                <CurrencyExchangeIcon
                  style={{ fontSize: "32px", color: "#5167F6" }}
                />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>Processing Fees</BenefitTitle>
                <BenefitDescription>
                  Charged by the payment processor or service provider that
                  facilitates transactions.
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>

            <BenefitItem feeType="passThrough">
              <IconWrapper>
                <CreditCardIcon
                  style={{ fontSize: "32px", color: "#FF6B6B" }}
                />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>
                  Assessment aka Scheme Fees <FeeBadge>Pass‑through</FeeBadge>
                </BenefitTitle>
                <BenefitDescription>
                  Charged by the card networks (e.g., Visa). These fees are
                  typically smaller than interchange fees, covering the cost of
                  maintaining the network infrastructure.
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>

            <BenefitItem feeType="passThrough">
              <IconWrapper>
                <AccountBalanceIcon
                  style={{ fontSize: "32px", color: "#FF6B6B" }}
                />
              </IconWrapper>
              <BenefitText>
                <BenefitTitle>
                  Interchange Fees <FeeBadge>Pass‑through</FeeBadge>
                </BenefitTitle>
                <BenefitDescription>
                  Charged by card-issuing banks (e.g., Chase) every time a card
                  is used. Interchange fees can vary based on the type of card
                  (e.g., rewards cards, debit cards, credit cards), the
                  transaction type, and the industry.
                </BenefitDescription>
              </BenefitText>
            </BenefitItem>
          </BenefitsContainer>

          <ReferenceImagesContainer>
            <ReferenceImageItem>
              <ReferenceImage src="/card_3.png" alt="Assessment Fees" />
              <ReferenceLabel>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <CreditCardIcon
                    style={{
                      fontSize: "16px",
                      marginRight: "4px",
                      color: "#FF6B6B",
                    }}
                  />
                  Assessment Fees
                </span>
              </ReferenceLabel>
            </ReferenceImageItem>
            <ReferenceImageItem>
              <ReferenceImage src="/image_4.png" alt="Interchange Fees" />
              <ReferenceLabel>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <AccountBalanceIcon
                    style={{
                      fontSize: "16px",
                      marginRight: "4px",
                      color: "#FF6B6B",
                    }}
                  />
                  Interchange Fees
                </span>
              </ReferenceLabel>
            </ReferenceImageItem>
          </ReferenceImagesContainer>
        </div>
      </Root>
    </>
  );
};

const Root = styled.div<{ show: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 24px;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 600px;
  z-index: 1000;
  display: ${(props) => (props.show ? "block" : "none")};
  border: 1px solid rgba(81, 103, 246, 0.1);
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: 800;
  color: #2a2a2a;
  margin: 0 0 24px 0;
  text-align: center;
  letter-spacing: -0.5px;
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;
`;

const BenefitItem = styled.div<{ feeType?: "processing" | "passThrough" }>`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: ${(props) =>
    props.feeType === "passThrough"
      ? "rgba(255, 107, 107, 0.08)"
      : "rgba(81, 103, 246, 0.08)"};
  border-radius: 16px;
  border: 1px solid
    ${(props) =>
      props.feeType === "passThrough"
        ? "rgba(255, 107, 107, 0.7)"
        : "rgba(81, 103, 246, 0.7)"};
  transition: all 0.2s ease;

  &:hover {
    transform: translateX(4px);
    background: ${(props) =>
      props.feeType === "passThrough"
        ? "rgba(255, 107, 107, 0.12)"
        : "rgba(81, 103, 246, 0.12)"};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(81, 103, 246, 0.08);
`;

const BenefitText = styled.div`
  flex: 1;
`;

const BenefitTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 0 0 4px 0;
`;

const BenefitDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  line-height: 1.5;
`;

const ConsentText = styled.p`
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  background: rgba(16, 185, 129, 0.05);
  padding: 16px;
  border-radius: 12px;
  border: 1px solid rgba(16, 185, 129, 0.1);
  margin: 24px 0;
`;

const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  z-index: 999;
  display: ${(props) => (props.show ? "block" : "none")};
  transition: all 0.3s ease;
`;

const ReferenceImage = styled.img`
  width: 200px;
  height: auto;
  margin-left: auto;
`;

const FeeBadge = styled.span`
  background-color: rgba(255, 107, 107, 0.85); /* A solid red tone */
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
`;

const ReferenceImagesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 24px;
`;

const ReferenceImageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ReferenceLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #2a2a2a;
  margin-top: 8px;
`;

export default ProcessingFeesModal;
